import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // staleTime: 5 * 60 * 1000, // 5 minutes
      // cacheTime: 15 * 60 * 1000 // 15 minutes
    },
  },
});

export function Providers({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        withNormalizeCSS
        withGlobalStyles
        theme={{
          colors: {
            accent: [
              "#F9E3E3",
              "#F1C8C7",
              "#EAAEAD",
              "#E59695",
              "#E0817E",
              "#DE6D69",
              "#DD5A56",
              "#DE4942",
              "#E1261C",
              "#C81107",
            ],
            primary: [
              "#F2F2F2",
              "#DEDDDC",
              "#C9C8C6",
              "#B4B4B0",
              "#9FA19C",
              "#8B908B",
              "#7C827D",
              "#707673",
              "#656C6C",
              "#54565B",
            ],
          },
          primaryShade: 8,
          primaryColor: "accent",
          colorScheme: "light",
          fontFamily: "Muli, sans-serif",
          fontFamilyMonospace: "Monaco, Courier, monospace",
          headings: { fontFamily: "Muli, sans-serif" },
          spacing: {
            xs: 8,
            sm: 12,
            md: 16,
            lg: 20,
            xl: 24,
          },
          components: {
            Anchor: {
              styles: {
                root: {
                  color: "black",
                },
              },
            },
            Button: {
              styles: {
                label: {
                  fontWeight: 400,
                },
              },
            },
          },
        }}
      >
        <ModalsProvider
          modalProps={{
            classNames: {
              modal: "w-fit",
            },
          }}
        >
          <NotificationsProvider position="top-right" zIndex={9999}>
            {children}
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
