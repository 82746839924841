import { QueryClient, QueryClientProvider } from "react-query";
import { Providers } from "@/provider";
import { FavIcon } from "@/provider/favicon";
import type { AppProps } from "next/app";

import "../styles/globals.scss";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <FavIcon />
      <Providers>
        <Component {...pageProps} />
      </Providers>
    </QueryClientProvider>
  );
}

export default MyApp;
